<template>
  <div id="pwa-prompt" v-if="deferredPrompt">
    Ajouter Azura à l'écran d'accueil ?
    <button tabindex="0" class="button" @click="install()">Installer!</button>
    <button type="button" class="close-icon" @click="dismiss()">
      <font-awesome-icon icon="times" />
    </button>
  </div>
  <router-view />
</template>

<script>
import Cookies from 'js-cookie'
export default {
  data () {
    return {
      deferredPrompt: null
    }
  },
  created () {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((swReg) => {})
    }

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      if (Cookies.get('add-to-home-screen') === undefined) {
        this.deferredPrompt = e
        try {
          this.installer()
        } catch (error) {}
      }
    })

    window.addEventListener('appinstalled', () => {
      this.deferredPrompt = null
    })
  },
  methods: {
    installer: function () {
      try {
        this.deferredPrompt.prompt().catch(() => {})
        this.deferredPrompt.userChoice.then((result) => {
          if (result.outcome === 'accepted') {
            this.deferredPrompt = null
          } else {
          }
        })
      } catch (error) {}
    },
    async dismiss () {
      Cookies.set('add-to-home-screen', null, { expires: 15 })
      this.deferredPrompt = null
    },
    async install () {
      try {
        this.deferredPrompt.prompt().catch(() => {})
        this.deferredPrompt.userChoice.then((result) => {
          if (result.outcome === 'accepted') {
            this.deferredPrompt = null
          } else {
            this.deferredPrompt = null
            // Cookies.set('add-to-home-screen', null, { expires: 15 })
          }
        })
      } catch (error) {}
    }
  }
}
</script>

<style scoped>
#pwa-prompt {
  position: fixed;
  z-index: 10001;
  font-size: 0.825rem;
  line-height: 1;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  background: #3e3f5e;
  color: white;
  transition: top 1s cubic-bezier(0.23, 1, 0.32, 1);
}
#pwa-prompt .button {
  font-size: 0.9rem;
  margin: 0 0 0 0.5rem;
  padding: 0.25em 0.5em;
  background-color: #615dfa;
  border-color: #615dfa;
  color: white;
  box-shadow: none;
  position: relative;
  cursor: pointer;
  line-height: inherit;
  font-weight: 700;
  font-family: 'Rajdhani', sans-serif;
  transition: all 0.15s;
  border: 2px solid #fff;
  text-decoration: none;
  display: inline-block;
  border-radius: 4px;
  height: auto;
  width: auto;
}
.close-icon {
  position: absolute;
  right: 2.25rem;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #dc3545;
  border-radius: 50%;
  color: #f8f9fa;
}
</style>

<style>
body {
  transition: background-color 250ms ease-in-out;
}

.custom-loader-bars {
  display: flex;
  justify-content: center;
  height: 40px;
}
.custom-loader-bars .loader-bar {
  width: 4px;
  height: 100%;
  margin-right: 4px;
  border-radius: 200px;
  transform: scaleY(0.2);
  animation: loader-bars 0.5s infinite alternate ease-in;
}
.custom-loader-bars .loader-bar:last-child {
  margin-right: 0;
}
.custom-loader-bars .loader-bar:nth-child(1) {
  background-color: #615dfa;
}
.custom-loader-bars .loader-bar:nth-child(2) {
  background-color: #5d71fb;
  animation-delay: 0.1s;
}
.custom-loader-bars .loader-bar:nth-child(3) {
  background-color: #5983fb;
  animation-delay: 0.2s;
}
.custom-loader-bars .loader-bar:nth-child(4) {
  background-color: #549afc;
  animation-delay: 0.3s;
}
.custom-loader-bars .loader-bar:nth-child(5) {
  background-color: #4eb2fd;
  animation-delay: 0.4s;
}
.custom-loader-bars .loader-bar:nth-child(6) {
  background-color: #49c9fe;
  animation-delay: 0.5s;
}
.custom-loader-bars .loader-bar:nth-child(7) {
  background-color: #45ddfe;
  animation-delay: 0.6s;
}
.custom-loader-bars .loader-bar:nth-child(8) {
  background-color: #41efff;
  animation-delay: 0.7s;
}
.custom-loader-container {
  margin-top: 2rem;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}
.page_wrapper .bottom_text p {
  font-weight: 800;
  text-align: center;
  line-height: 1.2;
  letter-spacing: -0.05vw;
  font-size: 20px;
  font-family: Manrope, sans-serif;
  margin: 0;
  margin-top: -0.6em;
  color: #fff;
  /* text-shadow: 0 0 0.05em #fff, 0 0 0.2em #343a40, 0 0 0.3em #343a40 !important; */
}
</style>
